@import 'styles/_variables';
@import 'styles/_mixins';

.m-availability {
  width: 100%;

  header {
    border-bottom: 1px solid rgba(26, 219, 116, 0.2);
    background-color: rgba(26, 219, 116, 0.2);

    span {
      color: #1adb74;
    }

    button {
      outline: none;

      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #1adb74 !important;
        }
      }
    }
  }

  &__table{
    color: #050e30;
    font-size: 14px;
    font-weight: 400;
    &--header{
      background-color: rgba(24, 161, 219, 0.1);
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
    }
    &--body{
      li{
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:nth-of-type(even){
          background-color: #f2f2f4;
        }
        input{
          width: 140px;
          padding: 5px 10px;
          border: 1px solid #ccc
        }
      }
    }
  }

}