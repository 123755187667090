@import 'styles/_variables';
@import 'styles/_mixins';

.m-generic{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  &__component{
    display: flex;
    flex-direction: column;
    input{
      height: 45px;
      border: 1px solid $colorPrimary;
      width: 100%;
      padding: 0 15px;
      border-radius: 5px;
    }
    strong{
      color: #050e30;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    p{
      color: #53586e;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &__action {
    margin-left: auto;
    padding-left: 15px;
    svg{
      width: 15px;
      height: 15px;
    }
  }
}