@import "styles/_variables";
@import "styles/_mixins";

.m-chatContact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 100%;
  background: #fff;
  position: relative;
  padding: 15px 30px 15px 20px;
  outline: none !important;
  transition: all .2s linear;

  &__placeholder {
    display: flex;
    width: 100%;
    align-items: center;

    &--avatar {
      min-width: 50px;
      height: 50px;
      margin-right: 15px;
      position: relative;;
      border-radius: 100%;
      @extend .animated-background;
    }

    &--message {
      width: 100%;
      display: flex;
      flex-direction: column;

      &:before {
        content: '';
        width: 100%;
        height: 25px;
        margin-bottom: 10px;
        @extend .animated-background;
      }

      &:after {
        content: '';
        width: 70%;
        height: 15px;
        @extend .animated-background;
      }
    }

    &--date {
      align-self: flex-start;
      min-width: 30px;
      height: 50px;
      margin-left: 15px;
      @extend .animated-background;
    }
  }

  &.-active,
  &:hover {
    background-color: #f5f5f5;
  }

  &:after {
    content: '';
    width: calc(100% - 85px);
    height: 1px;
    background: #f4f4f4;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  &.-newMessages{
    position: relative;
    &:after{
      content: '';
      width: 10px;
      height: 10px;
      background: $colorPrimary;
      border-radius: 100%;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  &__avatar {
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin-right: 15px;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 14px;
      height: 14px;
      border: 2px solid #fff;
      background-color: #ccc;
      border-radius: 100%;
      right: 0;
      bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 100%;
    }

    &.-away,
    &.-online {
      &:after {
        display: block;
      }
    }

    &.-away {
      &:after {
        background: #facf00;
      }
    }

    &.-online {
      &:after {
        background-color: #1cf52b;
      }
    }
  }

  &__info {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    strong {
      max-width: 100%;
      color: #282828;
      font-size: 17px;
      font-weight: 500;
    }

    p {
      max-width: 100%;
      opacity: 0.7;
      color: #282828;
      font-size: 13px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__lastUpdate {
    opacity: 0.7;
    color: #282828;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 5px 0 0 30px;
    margin-left: auto;
    align-self: flex-start;
  }
}
