@import 'styles/_variables';
@import 'styles/_mixins';

.m-aboutMe {
  width: 100%;

  header {
    border: 1px solid rgba(24, 161, 219, 0.2);
    background-color: rgba(24, 161, 219, 0.2);

    button {
      outline: none;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  main {
    p {
      font-size: 14px;
      color: #333;
      padding: 20px;
      white-space: pre-line;
      height: 200px;
      overflow-y: auto;

      &.-empty {
        width: 100%;
        text-align: center;
        opacity: .5;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    textarea {
      border: 1px solid #ccc;
      border-radius: 6px;
      width: calc(100% - 30px);
      height: 200px;
      margin: 15px;
      padding: 15px;
    }
  }

  footer {
    display: flex;
    padding: 15px;
    border-top: 1px solid #f2f3f8;
    margin-top: 15px;
    justify-content: space-between;
    position: relative;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    > div {
      max-width: calc(33% - 5px);

      @include media-breakpoint-down(xs) {
        max-width: 100%;
        &:not(:last-child){
          margin-bottom: 15px;
        }
      }

      &.-editing {
        max-width: 100%;
      }

      span {
        font-size: 14px;
      }
    }
  }

}