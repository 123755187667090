@import 'styles/_variables';
@import 'styles/_mixins';

.o-clientProfile {
  width: 100%;
  position: relative;
  background-color: #f2f3f8;
  padding: 10px 30px 100px;

  &:before {
    content: '';
    width: 100%;
    height: 280px;
    background-color: #050e30;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__container {
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__aside {
    width: 100%;
    max-width: 370px;
    margin-top: 40px;
    margin-right: 20px;

    &--box {
      background: #ffffff;
      border-radius: 6px;
      position: relative;
      z-index: 2;
      padding: 20px 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }

    &--avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid $colorPrimary;
      width: 200px;
      height: 200px;
      border-radius: 100%;
      position: relative;

      &:before {
        content: '';
        width: 17px;
        height: 17px;
        border-radius: 100%;
        position: absolute;
        bottom: 19px;
        right: 19px;
        background-color: #12b87f;
        border: 2px solid #fff;
      }
    }

    &--info {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        color: #050e30;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
      }

      span {
        border-top: 1px solid #ddd;
        color: $colorPrimary;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        text-align: center;
        margin-top: 15px;
        padding: 15px 15px 30px;
      }
    }

    &--form {
      width: 100%;
      margin-top: 30px;

      &-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        background-color: #f2f3f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 20px;

        header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          strong {
            color: #050e30;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
          }

          button {

            opacity: 0;
            outline: none;
            transition: all .2s linear;

            svg {
              width: 16px;
              height: 16px;
            }

            &.-editing {
              opacity: 1;

              svg {
                path {
                  fill: $colorPrimary
                }
              }
            }
          }
        }

        main {
          display: flex;
          flex-direction: column;

          span {
            color: #53586e;
            font-size: 16px;
            font-weight: 500;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          button {
            display: inline-flex;
            align-items: center;

            span {
              margin-left: 15px;
              color: #53586e;
              font-size: 16px;
              font-weight: 600;
              line-height: 1;
            }
          }

          input {
            border: 1px solid $colorPrimary;
            padding: 5px 10px;
            border-radius: 6px;
          }
        }

        .link {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }

        &:hover {
          button {
            opacity: 1;
          }
        }
      }
    }

    &--sra {
      width: 100%;
      text-align: center;
      padding: 0 0 20px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;

    &--inviteYourFirm {
      display: none;
      width: 100%;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    &--filter,
    &--box {
      width: 100%;
      max-width: calc(50% - 40px);
      margin: 40px 20px 0;
      background: #fff;
      border-radius: 6px;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      @include media-breakpoint-down(md) {
        margin: 40px 0 0;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%
      }

      strong {
        color: $colorPrimary;
      }
    }

    &--filter {
      max-width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: #424143;

        &.-active {
          color: $colorPrimary;
          text-decoration: underline;
        }
      }
    }

    &--box {
      overflow: hidden;

      header {
        border-bottom: 1px solid #e5e6ea;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: $colorPrimary;
          font-size: 24px;
          font-weight: 400;
        }

        svg {
          min-width: 24px;

          path {
            fill: $colorPrimary !important;
          }
        }

        @include media-breakpoint-down(sm) {
          span {
            font-size: 18px;
          }
        }
      }

      main {
        max-width: 100%;

        @include media-breakpoint-down(xs) {
          overflow-x: auto;
        }

        &.-withSpace {
          padding: 20px;
        }

        table {
          width: 100%;
          max-width: 100%;
          border-collapse: collapse;

          thead {
            tr {
              th {
                padding: 15px;
                color: #050e30;
                font-size: 16px;
                font-weight: 600;
                border: 1px solid #e5e6ea;
                border-top: none;
                text-align: left;
                border-left: none;

                &:last-child {
                  border-right: none;
                }

                &:last-child {
                  text-align: right;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 15px;
                color: #050e30;
                font-size: 14px;
                font-weight: 500;
                border: 1px solid #e5e6ea;
                border-left: none;

                &:last-child {
                  border-right: none;
                }

                button {
                  display: flex;
                  align-items: center;
                  border: 1px solid #a2a2a2;
                  background-color: #fff;
                  padding: 5px 10px;
                  border-radius: 5px;

                  svg {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }

      &-item {
        &.-full {
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .-payments {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      .-reviews {
        button {
          width: 100%;
          text-align: center;
          padding: 10px 20px;
          color: #050e30;
          font-size: 16px;
          font-weight: 600;
          transition: all .2s linear;

          &:hover {
            background: $colorPrimary;
            color: #fff;
          }
        }
      }
    }
  }
}