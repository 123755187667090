@import "styles/_variables";
@import "styles/_mixins";

.p-cases {
  width: 100%;
  position: relative;
  background-color: #f2f3f8;
  padding: 10px 30px 100px;
  min-height: calc(100vh - 85px);

  &:before {
    content: '';
    width: 100%;
    height: 280px;
    background-color: #050e30;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__container {
    position: relative;
    z-index: 2;
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 40px;
    flex-direction: column;
  }

  &__header {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    margin-bottom: 30px;

    strong {
      color: #18a1db;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @include media-breakpoint-down(sm) {
      order: 2;
    }

    &--item {
      width: calc(50% - 15px);
      max-width: calc(50% - 15px);
      height: 100%;
      flex-direction: column;
      margin: 0 0 30px;
      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;
      }

      &.-active {
        > header {
          color: #fff;
          @include media-breakpoint-down(sm) {
            color: #050e30;
          }
        }
      }

      > header {
        font-size: 16px;
        font-weight: 600;
        margin: 20px 0;
        color: #050e30;

        span {
          margin-left: 5px;
        }
      }

      > main {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  &__newOffer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    @include media-breakpoint-down(sm) {
      order: 1;
      margin-bottom: 30px;
    }

    &--box {
      display: flex;
      flex-direction: column;
      background: $colorPrimary;
      min-width: 370px;
      border-radius: 6px;
      padding: 30px;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        padding: 15px;
        min-width: unset;
      }

      h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 40px;
      }

      img {
        @include media-breakpoint-down(sm) {
          height: 100px;
        }
      }

      a {
        margin-top: 30px;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 6px;
        transition: all .2s linear;
        @include media-breakpoint-down(sm) {
          height: 40px
        }

        span {
          color: #050e30;
          font-size: 16px;
          font-weight: 400;
        }

        svg {
          margin-left: 10px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }

    &--howSendOffer {
      width: 100%;
      max-width: calc(100% - 400px);
      max-height: 370px;
      position: relative;
      display: flex;
      flex: 1;
      @include media-breakpoint-down(md) {
        max-width: 100%;
        margin-top: 30px;
      }

      @include media-breakpoint-down(sm) {
        display: none
      }

      button {
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        transition: all .2s linear;
        border: 3px solid transparent;

        &:hover {
          border: 3px solid #18a1db;
        }

        img {
          width: 100%;
          height: 370px;
          object-fit: cover;
        }

        > div {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          strong {
            margin-top: 20px;
            color: #fff;
            font-size: 24px;
            font-weight: 400;
          }
        }
      }
    }
  }
}