@import "styles/_variables";
@import "styles/_mixins";

.p-manageCase {
  width: 100%;
  min-height: calc(100vh - 84px);
  background-color: #f2f3f8;
  padding: 10px 30px 100px;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 280px;
    background-color: #050e30;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__container {
    position: relative;
    z-index: 2;
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 40px;
    flex-direction: column;
  }

  &__header {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    margin-bottom: 30px;

    strong {
      color: #18a1db;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    small {
      &.-gray {
        margin-top: 5px;
        color: #777;
      }
    }

    &--card {
      width: 100%;
      max-width: 50%;
      @include media-breakpoint-down(md) {
        max-width: 60%;
      }
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin-bottom: 30px;
      }
    }

    &--actions {
      width: 100%;
      max-width: calc(50% - 30px);
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        max-width: calc(40% - 30px);
      }
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }

    &--paymentInfo {
      display: flex;
      flex-direction: column;
      padding: 0 20px 30px;
    }

    &--calculation {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      &.-start{
        align-items: flex-start;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }

      &:last-child {
        padding-bottom: 0;
      }

      p {
        color: #939393;
        font-size: 16px;
        font-weight: 400;
      }

      > span {
        color: #939393;
        font-size: 16px;
        font-weight: 400;
      }

      @include media-breakpoint-down(xs) {
        p, > span {
          font-size: 14px;
        }
      }
    }
  }

  &__actionContent {
    &.-requestExtension {
      display: flex;
      align-items: center;
      padding: 15px 20px 0;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        p {
          order: 1;
          padding: 15px 10px;
        }
        .-field {
          order: 2;
        }
      }
    }
    &.-reportDispute{
      flex-direction: column;
      align-items: flex-start;
      small{
        align-self: flex-end;
        color: #858585;
        margin-top: 5px;
      }
    }
  }
}