@import "styles/_variables";
@import "styles/_mixins";

.p-newOffer {
  width: 100%;
  min-height: calc(100vh - 84px);
  background-color: #f2f3f8;
  padding: 10px 30px 100px;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 280px;
    background-color: #050e30;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__container {
    position: relative;
    z-index: 2;
    max-width: 520px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 40px;
    flex-direction: column;
  }

  &__header {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    margin-bottom: 30px;

    strong {
      color: #18a1db;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 6px;
    padding: 20px 30px;
    width: 100%;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--field {
      width: 100%;

      &.-paymentType {
        max-width: calc(60% - 15px);
      }

      &.-amount {
        max-width: calc(40% - 15px);
      }

      @include media-breakpoint-down(xs) {
        &.-amount,
        &.-paymentType {
          max-width: 100%;
        }
      }

      &.-total,
      &.-estimatedHours,
      &.-completionDate {
        display: flex;
        width: 100%;
        align-items: center;

        > span {
          width: 100%;
          max-width: calc(60% + 15px);
          display: block;
          @include media-breakpoint-down(xs) {
            font-size: 14px;
          }
        }
      }

      &.-total,
      &.-estimatedHours {
        text-align: right;

        > span {
          width: 100%;
          max-width: calc(60% + 15px);
          padding-right: 30px;
          @include media-breakpoint-down(xs) {
            padding-right: 15px;
          }
        }
      }
    }

    &--calculation {
      width: 100%;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(.-legalAid) {
        padding: 15px 0;
        border-bottom: 1px solid #eee;
      }

      p {
        color: #a2a2a2;
        font-size: 16px;
        font-weight: 400;
      }

      > span {
        color: #a2a2a2;
        font-size: 16px;
        font-weight: 400;
      }

      @include media-breakpoint-down(xs) {
        p, > span {
          font-size: 14px;
        }
      }
    }

    &--action {
      width: 100%;
      margin-top: 30px;
    }
  }

}