@import 'styles/_variables';
@import 'styles/_mixins';

.o-results {
  position: relative;

  > .flex {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__content {
    background: #fff;
    z-index: 2;
    width: 100%;
    max-width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 30px;

    &--loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        max-width: map-get($grid-breakpoints, lg) / 2;
        padding-right: calc(10% + 20px);
        margin-left: auto;
      }
      @include media-breakpoint-up(xl) {
        max-width: map-get($grid-breakpoints, xl) / 2;
      }

      @include media-breakpoint-down(md) {
        max-width: map-get($grid-breakpoints, md);
        padding: 0 30px;
        margin: auto;
      }
      @include media-breakpoint-down(sm) {
        max-width: map-get($grid-breakpoints, sm) + 60px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin: auto;
      padding: 15px 15px 50px;
      min-height: unset;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding: 0;
    }

    &--container {
      position: relative;
      max-width: 100%;
      min-height: 300px;
      @include media-breakpoint-up(lg) {
        max-width: map-get($grid-breakpoints, lg) / 2;
        padding-right: calc(10% + 20px);
        margin-left: auto;
      }
      @include media-breakpoint-up(xl) {
        max-width: map-get($grid-breakpoints, xl) / 2;
      }

      @include media-breakpoint-down(md) {
        max-width: map-get($grid-breakpoints, md);
        padding: 0 30px;
        margin: auto;
      }
      @include media-breakpoint-down(sm) {
        max-width: map-get($grid-breakpoints, sm) + 60px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }

    &--sortFilterContainer {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &--actions {
      display: flex;
      width: 100%;
      justify-content: space-between;

      &-map {
        display: none;
      }

      > div {
        width: 100%;
        max-width: calc(50% - 15px);

        button {
          width: 100%;

          span {
            font-weight: 600;
            order: 2;
          }

          svg {
            width: 17px;
            height: 17px;
            min-width: 13px;
            margin-right: 10px;

            path {
              transition: all 0.2s linear;
              fill: $colorPrimary;
            }
          }

          &:hover {
            border-color: $colorPrimary;

            svg {
              path {
                fill: #fff;
              }
            }
          }
        }

        &.-active {
          button {
            color: #fff;
            background: $colorPrimary;
            border-color: $colorPrimary;

            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        > div {
          max-width: 33.33%;

          button {
            width: 100%;
            border: 1px solid #ddd;
            background-color: #f8f9ff;
            outline: none;

            span {
              order: 2;
            }

            svg {
              margin: 0 10px 0 0;
            }
          }
        }

        &-sort {
          order: 1;

          button {
            border-radius: 5px 0 0 5px;
            border-right: none !important;

            &:hover {
              border-color: $colorPrimary;
            }
          }
        }

        &-filter {
          order: 2;

          button {
            border-radius: 0;
            border-color: #ddd;

            &:hover {
              border-color: $colorPrimary;
            }
          }
        }

        &-map {
          width: 100%;
          display: flex;
          max-width: 33.33%;
          order: 3;
          justify-content: center;
          align-items: center;

          button {
            border-radius: 0 5px 5px 0;
            border-left: none !important;
          }
        }
      }
    }

    &--info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
      overflow: hidden;
      border-radius: 5px;
      background-color: #f8f9ff;
      @include media-breakpoint-down(md) {
        display: none;
      }

      &-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 30px;
        white-space: nowrap;
        text-align: center;

        color: #888;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        b {
          color: #222;
          font-size: 16px;
        }

        &:first-child {
          background-color: $colorPrimary;

          span {
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }

      &-divider {
        width: 1px;
        background: #d8d8d8;
        display: inline-block;
        height: 50px;
      }
    }

    &--list {
      width: 100%;

      &-item {
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
          padding-right: 0;
        }

        .m-lawyerCard {
          @include media-breakpoint-down(md) {
            max-width: unset;
          }

          &__extraInfo {
            margin: 0;
            justify-content: flex-start;

            &--divider {
              margin: 0 auto;
              padding: 0 15px;
              @include media-breakpoint-down(xs) {
                margin: 0 5px;
              }
            }

            &--fee {
              white-space: nowrap;
              line-height: 1;
              height: 40px;
              padding: 10px 15px;
              @include media-breakpoint-down(lg) {
                font-size: 13px;
                padding: 10px;
              }
              @include media-breakpoint-down(xs) {
                font-size: 12px;
                &Icon {
                  display: none;
                }
              }

              span {
                padding-top: 1px;

                br {
                  display: none;
                }
              }
            }

            &--hourRate {
              min-width: unset;
              flex-direction: row;
              line-height: 1;
              height: 40px;
              padding: 10px 15px;

              @include media-breakpoint-down(lg) {
                font-size: 13px;
                padding: 5px 10px;
              }

              @include media-breakpoint-down(xs) {
                flex-wrap: wrap;
                font-size: 10px;
                span {
                  padding-top: 2px;
                }
              }

              strong {
                margin: 0 0 0 5px;
                font-size: 16px;
                @include media-breakpoint-down(lg) {
                  font-size: 13px;
                }
              }
            }
          }

          @include media-breakpoint-between(sm, md) {
            flex-direction: row;
            align-items: center;
            padding: 15px;
            &__image {
              margin-bottom: 0;
            }

            &__content {
              width: 100%;
              max-width: 60%;
              padding-right: 30px;
            }

            &__extraInfo {
              width: 100%;
              max-width: 40%;
              flex-direction: column;
              border-left: 1px solid #ddd;
              padding: 0 10px 0 30px;

              > div {
                text-align: center;
                width: 100%;
              }
            }
          }
        }
      }
    }

    &--paginator {
      width: 100%;

      height: 90px;
      margin: 30px 0 0 -30px;
      box-shadow: 0 -8px 7px rgba(175, 175, 175, 0.26);
      background-color: #fff;
      @include media-breakpoint-down(md) {
        margin: 30px 0 0;
      }

      ul {
        display: flex;
        justify-content: flex-start;
        @include media-breakpoint-up(xl) {
          padding-left: 30px;
        }

        @include media-breakpoint-down(lg) {
          justify-content: center;
        }

        li {
          margin: 15px 10px 15px 0;

          button {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #f8f9ff;
            color: #858585;
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0.96px;

            &:disabled {
              opacity: 0.3;
            }

            @include media-breakpoint-down(lg) {
              width: 50px;
              min-width: unset;
              height: 50px;
            }

            svg {
              width: 15px;
              height: 15px;
            }
          }

          &.-active {
            button {
              background: $colorPrimary;
              color: #fff;
            }
          }
        }
      }
    }

    .simplebar-track {
      right: -12px;
      width: 10px;
      border-radius: 10px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .simplebar-scrollbar {
      width: 10px;

      &:before {
        background: $colorPrimary;
        border-radius: 5px;
      }
    }

    &--empty {
      display: flex;
      min-height: 600px;
      align-items: center;
      justify-content: center;
      background: #f8f9ff;
      border-radius: 10px 10px 0 0;
      font-size: 32px;
      color: #d2d8fc;
    }
  }

  &__map {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 55%;
    display: flex;
    z-index: 2;
    box-shadow: 0 0 35px rgba(133, 133, 133, 0.16);
    border: 4px solid #fff;
    border-radius: 30px 0 0;
    overflow: hidden;

    &--cards,
    &--mobileButtons {
      display: none;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      position: relative;
      min-height: unset;
    }

    &--action {
      width: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      @include media-breakpoint-down(sm) {
        min-height: 500px;
      }

      //img {
      //  width: 100%;
      //  height: 100%;
      //  min-height: 500px;
      //  object-fit: cover;
      //  object-position: center;
      //}

      button {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;

        letter-spacing: 1.2px;
        border-radius: 5px;
        border: 3px solid #fff;
        padding: 30px 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        @include translate(-50%, -50%);

        @include media-breakpoint-down(lg) {
          padding: 20px 40px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__content {
      &.-hide {
        display: none;
      }
    }
    &__map {
      width: 100%;
      max-width: 100%;
      position: relative;
      min-height: 100vh;
      border: none;
      box-shadow: none;
      border-radius: 0;
      margin-top: -30px;

      &--mobileButtons {
        display: flex;
        position: absolute;
        z-index: 2;
        padding: 30px;
        width: 100%;
        justify-content: space-between;

        &-zoom {
          display: flex;
          flex-direction: column;
          max-width: 35px;

          a {
            width: 35px;
            max-width: 35px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            background: #fff;
            text-align: center;
            line-height: 1;
            position: relative;

            &:first-child {
              color: $colorPrimary;
              border-radius: 5px 5px 0 0;

              &:after,
              &:before {
                content: '';
                width: 14px;
                height: 2px;
                background: $colorPrimary;
                display: inline-block;
              }

              &:after {
                width: 2px;
                height: 14px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -7px 0 0 -1px;
              }
            }

            &:last-child {
              color: #fff;
              border-radius: 0 0 5px 5px;
              background: $colorPrimary;

              &:before {
                content: '';
                width: 14px;
                height: 2px;
                background: #fff;
                display: inline-block;
              }
            }
          }
        }

        &-list {
          width: 100px;
          flex: 0;

          a {
            display: flex;
            align-items: center;
            background: #fff;
            color: #18a1db;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 15px;
            border-radius: 5px;
            line-height: 1;

            svg {
              margin-right: 5px;
            }
          }
        }
      }

      &.-show {
        display: flex;
      }

      &--action {
        height: 100%;
        min-height: 100vh;

        //img {
        //  min-height: 100vh;
        //}
      }

      &--cards {
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: 30px;

        &--item {
          padding: 0 15px;
        }

        .owl-nav {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          margin-top: -15px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-width: 420px;
        }

        .owl-prev,
        .owl-next {
          width: 30px;
          height: 30px;
          background: $colorPrimary;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 10px;
            height: 10px;

            border: 2px solid #fff;
            border-left: none;
            border-bottom: none;
            transform: rotate(45deg);
            margin-left: -3px;

            &.chevron-left {
              border: 2px solid #fff;
              border-right: none;
              border-top: none;
              margin-left: 3px;
            }
          }
        }

        .m-lawyerCard {
          margin-bottom: 0;
          max-width: 390px;

          &__extraInfo {
            margin: 0;
            justify-content: flex-start;

            &--divider {
              margin: 0 auto;
              padding: 0 10px;
            }

            &--fee {
              white-space: nowrap;
              line-height: 1;
              height: 40px;
              padding: 10px 15px;
              @include media-breakpoint-down(lg) {
                font-size: 13px;
                padding: 10px;
              }
              @include media-breakpoint-down(xs) {
                font-size: 12px;
                &Icon {
                  display: none;
                }
              }

              span {
                padding-top: 1px;

                br {
                  display: none;
                }
              }
            }

            &--hourRate {
              min-width: unset;
              flex-direction: row;
              line-height: 1;
              height: 40px;
              padding: 10px 15px;

              @include media-breakpoint-down(lg) {
                font-size: 13px;
                padding: 5px;
              }

              @include media-breakpoint-down(xs) {
                flex-wrap: wrap;
                font-size: 10px;
              }

              strong {
                margin: 0 0 0 5px;
                font-size: 16px;
                @include media-breakpoint-down(lg) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}
