@import 'styles/_variables';
@import 'styles/_mixins';

.a-caseAction {
  margin-bottom: 20px;

  &.-open {
    border-radius: 6px;
    background-color: #fff;
  }

  &__button {
    padding: 12px 15px;
    border-radius: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #050e30;
    font-weight: 500;
    transition: all 0.2s linear;

    &.-withoutAction {
      cursor: default !important;
    }

    &:not(.-withoutAction) {
      &:hover {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    svg {
      max-width: 22px;
      max-height: 25px;
      path {
        transition: all 0.2s linear;
      }
    }

    &.-open {
      background: transparent !important;
      border-color: #fff !important;
      border-bottom-color: #1adb74 !important;
      border-radius: 6px 6px 0 0 !important;
    }
  }

  &.-markAsComplete {
    .a-caseAction__button {
      border: 2px solid #1adb74;
      background-color: #dbf1eb;

      &:not(.-withoutAction) {
        &:hover {
          svg {
            path {
              fill: #1adb74;
            }
          }
        }
      }

      &.-open {
        color: #1adb74;

        svg {
          path {
            fill: #1adb74;
          }
        }
      }
    }
  }

  &.-requestExtension {
    .a-caseAction__button {
      border: 2px solid #18a1db;
      background-color: #dbeaf6;

      &:not(.-withoutAction) {
        &:hover {
          svg {
            path {
              fill: #18a1db;
            }
          }
        }
      }

      &.-open {
        color: #18a1db;

        svg {
          path {
            fill: #18a1db;
          }
        }
      }
    }
  }

  &.-cancelCase {
    .a-caseAction__button {
      border: 2px solid #db1818;
      background-color: #f0dce1;

      &:not(.-withoutAction) {
        &:hover {
          svg {
            path {
              fill: #db1818;
            }
          }
        }
      }

      &.-open {
        color: #db1818;

        svg {
          path {
            fill: #db1818;
          }
        }
      }
    }
  }

  &.-reportADispute {
    .a-caseAction__button {
      border: 2px solid #ffc107;
      background-color: #f5eac8;

      &:not(.-withoutAction) {
        &:hover {
          svg {
            path {
              fill: #ffc107;
            }
          }
        }
      }

      &.-open {
        color: #ffc107;

        svg {
          path {
            fill: #ffc107;
          }
        }
      }
    }
  }

  &.-orderReviews {
    textarea {
      border: none;
    }
  }

  &.-orderReviews,
  &.-viewInvoice {
    .a-caseAction__button {
      border: 2px solid #b4adad;
      background-color: #ebecf1;

      &:not(.-withoutAction) {
        &:hover {
          svg {
            text,
            path {
              fill: #b4adad;
            }
          }
        }
      }
    }
  }

  &__content {
    p {
      padding: 15px 20px;

      &.-gray {
        padding-top: 0;
        color: #a2a2a2;
      }
    }

    textarea {
      border: 1px solid #ccc;
      width: 100%;
      height: 120px;
      padding: 10px 15px;
      border-radius: 6px;
      resize: none;
    }

    > button {
      margin-top: 20px;
      width: 100%;
      height: 60px;
      background-color: #1adb74;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 6px 6px;
    }
  }
}
