.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__indicator {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #009ed2;
    border-right-color: transparent;
    transform-origin: center;
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
