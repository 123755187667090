@import 'styles/_variables';
@import 'styles/_mixins';


.m-practiceAreas{
  padding: 0 20px 20px;
  &__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    button{
      outline: none;
      svg{
        width: 20px;
        height: 20px;
        path {
          fill: #999 !important;
        }
      }
    }
    span{
      color: #050e30;
      font-size: 16px;
      font-weight: 400;
    }
  }
  &__main{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    overflow-y: auto;
    max-height: 300px;
    .divider{
      width: 100%;
      margin: 15px 5px 10px;
      border-bottom: 1px solid rgba(5, 14, 48, 0.2);
      padding-bottom: 5px;
    }
    .chip{
      background-color: #f2f3f8;
      border-radius: 5px;
      padding: 5px 10px;
      color: #050e30;
      font-size: 14px;
      font-weight: 400;
      margin: 5px;
    }
  }
}