@import 'styles/_variables';
@import 'styles/_mixins';

.p-notifications {
  width: 100%;
  position: relative;
  padding: 50px 30px 100px;

  &__container {
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__box {
    overflow: hidden;
    width: 100%;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 6px;
    @include media-breakpoint-down(md) {
      order: 2;
    }

    &.-aside {
      width: 100%;
      max-width: 345px;
      margin-left: 50px;

      @include media-breakpoint-down(md) {
        order: 1;
        max-width: 100%;
        margin: 0 0 30px;
      }
    }
  }

  &__items {
    flex: 1;

    header {
      width: 100%;
      padding: 20px 25px;
      border-bottom: 1px solid #eee;
      line-height: 1;
      display: flex;
      align-items: center;

      strong {
        color: #424143;
      }
      button {
        margin-left: auto;
        color: #666;
        outline: none;
        &:hover{
          color: $colorPrimary;
        }
      }
    }

    &--loadMore {
      width: 100%;
      text-align: center;
      padding: 20px;
      color: #424143;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      transition: all 0.2s linear;
      &:hover {
        background: $colorPrimary;
        color: #fff;
      }
    }
    &--noItems{
      width: 100%;
      padding: 85px 30px;
      text-align: center;
      font-size: 22px;
      color: #ccc;
    }
  }

  &__aside {
    padding: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    header {
      margin-bottom: 15px;

      strong {
        color: #424143;
        font-size: 16px;
        font-weight: 600;
      }

      b {
        font-weight: 600;
        color: $colorPrimary;
        font-size: 18px;
        margin-left: 5px;
      }
    }

    &--icon {
      position: relative;
      margin-bottom: 15px;

      span {
        position: absolute;
        background-color: #ec1111;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        top: 25px;
        right: 25px;
      }
    }

    &--text {
      color: #424143;
      font-size: 16px;

      b {
        color: #ec1111;
      }
    }
  }
}
