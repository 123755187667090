@import 'styles/_variables';
@import 'styles/_mixins';

.o-lawyerDashboard {
  width: 100%;
  position: relative;
  background-color: #f2f3f8;
  padding: 10px 30px 100px;

  &:before {
    content: '';
    width: 100%;
    height: 280px;
    background-color: #050e30;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__container {
    max-width: 1366px;
    margin: auto;

  }

  &__row{
    margin: 0 -20px;
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__aside {
    width: 100%;
    max-width: 370px;
    background: #fff;
    border-radius: 6px;
    position: relative;
    z-index: 2;
    margin: 40px 20px 0;
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    &--status {
      line-height: 1;
      border: 2px solid #12b87f;
      margin-top: 15px;
      font-size: 14px;
      padding: 8px 15px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #12b87f;
      background: #c9eae0;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: block;
        background: #12b87f;
        margin-right: 10px;
      }
    }

    &--avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid $colorPrimary;
      width: 200px;
      height: 200px;
      border-radius: 100%;
      position: relative;

      &:before {
        content: '';
        width: 17px;
        height: 17px;
        border-radius: 100%;
        position: absolute;
        bottom: 19px;
        right: 19px;
        background-color: #12b87f;
        border: 2px solid #fff;
      }
    }

    &--info {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        color: #050e30;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &-rating {
        color: #424143;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 5px;
          padding-top: 3px;
        }
      }
    }

    &--form {
      width: 100%;
      margin-top: 30px;

      &-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        background-color: #f2f3f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 20px;

        header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          strong {
            color: #050e30;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
          }

          button {
            outline: none;

            svg {
              width: 16px;
              height: 16px;
            }

            &.-editing {
              svg {
                path {
                  fill: $colorPrimary;
                }
              }
            }
          }
        }

        main {
          display: flex;
          flex-direction: column;

          span {
            color: #53586e;
            font-size: 16px;
            font-weight: 500;
          }

          button {
            display: inline-flex;
            align-items: center;

            span {
              margin-left: 15px;
              color: #53586e;
              font-size: 16px;
              font-weight: 600;
              line-height: 1;
            }
          }

          input {
            border: 1px solid $colorPrimary;
            padding: 5px 10px;
            border-radius: 6px;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;

    &--filter,
    &--box {
      width: 100%;
      max-width: calc(50% - 40px);
      margin: 40px 20px 0;
      background: #fff;
      border-radius: 6px;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      @include media-breakpoint-down(md) {
        margin: 40px 0 0;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
      &.-welcome {
        max-width: calc(100% - 20px);
        margin: 30px 0 0;
        @include media-breakpoint-down(md) {
          max-width: 100%;
        }
        main{
          padding: 30px;
        }
      }
    }

    &--filter {
      max-width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: #424143;

        &.-active {
          color: $colorPrimary;
          text-decoration: underline;
        }
      }
    }

    &--box {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      &.-full {
        max-width: 100% !important;
      }

      header {
        border-bottom: 1px solid #e5e6ea;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: $colorPrimary;
          font-size: 24px;
          font-weight: 400;
        }

        svg {
          min-width: 24px;

          path {
            fill: $colorPrimary !important;
          }
        }

        @include media-breakpoint-down(sm) {
          span {
            font-size: 18px;
          }
        }
      }

      main {
        height: 100%;
        max-width: 100%;

        @include media-breakpoint-down(xs) {
          overflow-x: auto;
        }

        &.-withSpace {
          padding: 20px;
        }

        table {
          width: 100%;
          max-width: 100%;
          border-collapse: collapse;

          thead {
            tr {
              th {
                padding: 15px;
                color: #050e30;
                font-size: 16px;
                font-weight: 600;
                border: 1px solid #e5e6ea;
                border-top: none;
                text-align: left;
                border-left: none;
                &:last-child {
                  border-right: none;
                }

                &:last-child {
                  text-align: right;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 15px;
                color: #050e30;
                font-size: 14px;
                font-weight: 500;
                border: 1px solid #e5e6ea;
                border-left: none;
                &:last-child {
                  border-right: none;
                }

                a {
                  display: flex;
                  align-items: center;
                  border: 1px solid #a2a2a2;
                  background-color: #fff;
                  padding: 5px 10px;
                  border-radius: 5px;

                  svg {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }

      &-item {
        &.-full {
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .-welcome {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
          width: 80px;
          height: 80px;
          margin-bottom: 30px;
          path {
            fill: #ccc;
          }
        }
        button {
          margin-top: auto;
          background: $colorPrimary;
          color: #fff;
          display: inline;
          padding: 5px 30px;
          border-radius: 50px;
          font-size: 14px;
        }
      }

      .-payments {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      .-reviews {
        display: flex;
        flex-direction: column;
        .-items {
          margin-bottom: auto;
          flex: 1;
          max-height: 320px;
          overflow-y: auto;
        }

        &:not(.-showAll) {
          .-items {
            max-height: unset;
            overflow-y: visible;
            .m-reviewItem:nth-child(n + 4) {
              display: none;
            }
          }
        }
        button {
          background: #fefefe;
          margin-top: auto;
          width: 100%;
          text-align: center;
          padding: 10px 20px;
          color: #050e30;
          font-size: 16px;
          font-weight: 600;
          transition: all 0.2s linear;
          outline: none;
          &:hover {
            background: $colorPrimary;
            color: #fff;
          }
        }
      }
    }
  }
}
