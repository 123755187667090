@import 'styles/_variables';
@import 'styles/_mixins';

.m-mainExpertise{
  display: flex;
  flex-direction: column;
  header{
    border: 1px solid rgba(225, 177, 33, 0.2);
    background-color: rgba(225, 177, 33, 0.2);
    span{
      color: #e1b121;
      font-size: 24px;
      font-weight: 400;
    }
    button{
      outline: none;
      svg{
        width: 20px;
        height: 20px;
        path {
          fill: #e1b121 !important;
        }
      }
    }
  }
  main{
    padding: 20px;
    border-bottom: 1px solid rgba(5, 14, 48, 0.1);
    margin: 0 -5px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .chip{
      background-color: #f2f3f8;
      border-radius: 5px;
      padding: 5px 10px;
      color: #050e30;
      font-size: 14px;
      font-weight: 400;
      margin: 5px;
    }
  }
}