@import 'styles/_variables';
@import 'styles/_mixins';

.m-caseCard {
  width: 100%;
  min-height: 280px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  transition: all .2s linear;

  @include media-breakpoint-down(lg) {
    min-height: 310px;
  }
  @include media-breakpoint-down(md) {
    min-height: unset;
  }

  &:not(.-withoutHover){
    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    padding: 30px;
    @include media-breakpoint-down(sm) {
      padding: 15px;
    }

    &--image {
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
      }
    }

    &--info {
      flex: 1;
      padding-left: 30px;
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }

      header {
        display: flex;
        justify-content: space-between;
      }
    }

    &--client {
      display: flex;
      flex-direction: column;
      strong {
        color: #18a1db;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
      }

      span {
        color: #050e30;
        font-size: 16px;
        font-weight: 500;
      }
    }

    &--deadline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      color: #050e30;
      font-size: 14px;
      font-weight: 400;
      margin-left: auto;

      strong {

        font-weight: 600;
        color: #db1818;
      }
    }

    main {
      padding: 15px 0 5px;

      p {
        color: #a2a2a2;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  > footer {
    width: 100%;
    margin-top: auto;

    span {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
  }

  &.-active {
    > footer {
      span {
        background-color: #1adb74;
      }
    }
  }

  &.-complete,
  &.-completed_by_lawyer {
    > footer {
      span {
        background-color: #db1818;
      }
    }
  }

  &.-offer {
    > footer {
      span {
        background-color: #ffc107;
      }
    }
  }
}