@import 'styles/_variables';
@import 'styles/_mixins';

.m-caseReviews {
  &__loading {
    position: relative;
    min-height: 200px;
  }
  &__noReview {
    text-align: center;
    padding: 50px !important;
    width: 100%;
    display: block;
    color: #666;
  }
  &__reviews {
    padding: 20px;
    &--item {
      margin-bottom: 30px;
      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
        padding-bottom: 30px;
      }
      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p {
        padding: 0;
        margin: 15px 0 0;
        font-size: 14px;
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
  &__form {
    &--fields {
      padding: 20px;
    }
    &--label {
      margin-bottom: 10px;
    }
    &-stars {
      display: flex;
      align-items: center;
      line-height: 1;
      flex-wrap: wrap;
      span {
        margin-right: 15px;
      }
    }
  }
}
