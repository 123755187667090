@import 'styles/_variables';
@import 'styles/_mixins';

.m-hourPrice {
  padding: 20px;

  &__box {

    display: flex;
    background-color: #f2f3f8;
    padding: 20px;
    border-radius: 6px;
    align-items: center;

    i{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $colorPrimary;
      background-color: #d0ebf7;
      border-radius: 100%;
      color: $colorPrimary;
      margin-right: 10px;
    }
    input{
      width: 80px;
      height: 45px;
      padding: 0 15px;
      text-align: center;
      margin-right: 10px;
    }

    button {
      margin-left: auto;
      outline: none;

      svg {
        width: 15px;
        height: 15px;

        path {
          fill: #666 !important;
        }
      }
    }
  }

}