@import 'styles/_variables';
@import 'styles/_mixins';

.o-chatConversation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 84px);
  background-color: #f5f5f5;
  position: relative;

  @include media-breakpoint-down(sm) {
    max-height: calc(100vh - 69px);
  }

  &__empty {
    text-align: center;
    align-self: center;
    justify-self: center;
    margin: auto;
    font-size: 22px;
    opacity: 0.2;
    padding-bottom: 42px;
    max-width: 460px;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    width: 100%;
    background-color: #fefefe;
    position: relative;
    padding: 15px 30px 14px 20px;

    &--close {
      display: none;
      @include media-breakpoint-down(sm) {
        display: inline-block;
      }

      svg {
        width: 15px;
      }

      margin-right: 10px;
    }

    &--avatar {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 15px;

      @include media-breakpoint-down(sm) {
        width: 35px;
        height: 35px;
        min-width: 35px;
      }

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 100%;
      }
    }

    &--info {
      strong {
        color: #282828;
        font-size: 17px;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }
      }

      p {
        opacity: 0.7;
        color: #282828;
        font-size: 13px;
        font-weight: 400;
        text-transform: capitalize;
        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }
      }
    }

    &--actions {
      display: flex;
      margin-left: auto;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: 30px;
        transition: all 0.2s linear;

        @include media-breakpoint-down(sm) {
          margin-left: 15px;
          &:first-child {
            margin-left: 0;
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__notice {
    background-color: #fff;
    padding: 7px 10px;
    border-top: 1px solid #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: block;
      font-size: 12px;
      color: #777;
    }

    svg {
      cursor: pointer;
      margin-right: 10px;
      margin-left: 20px;
      width: 12px;
      fill: #ccc;
    }
  }

  &__messages {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 0;
    position: relative;
  }

  &__form {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: calc(100% - 420px);
    z-index: 99;
    @include media-breakpoint-down(md) {
      max-width: calc(100% - 260px);
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
}
