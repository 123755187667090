@import "styles/_variables";
@import "styles/_mixins";

.p-panelMessagesPage {
  max-height: calc(100vh - 84px);
  display: flex;
  overflow: hidden;
  font-family: $defaultPanelFontFamily;
  @include media-breakpoint-down(sm) {
    max-height: calc(100vh - 69px);
  }

  &__contacts {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 84px);
    max-width: 420px;
    background: #fff;
    border-right: 1px solid #f4f4f4;
    @include media-breakpoint-down(sm) {
      max-height: calc(100vh - 69px);
    }

    &--empty {
      display: flex;
      flex: 1;
      height: 100%;
      min-height: calc(100vh - 84px);
      align-items: center;
      justify-content: center;
      font-size: 22px;
      opacity: .2;
      padding-bottom: 42px;
      @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 69px);
      }
    }
  }

  &__content {
    width: 100%;
    height: 100vh;
    max-height: calc(100vh - 84px);
    @include media-breakpoint-down(sm) {
      max-height: calc(100vh - 69px);
    }
  }

  @include media-breakpoint-down(md) {
    &__contacts {
      max-width: 260px;
    }
  }
  @include media-breakpoint-down(sm) {
    position: relative;
    &__contacts {
      max-width: 100%;
    }
    &__content {
      position: absolute;
      top: 0;
      left: 100%;
      transition: all .2s linear;
      opacity: 0;

      &.-active {
        opacity: 1;
        left: 0;
      }
    }
  }
}
