@import 'styles/_variables';
@import 'styles/_mixins';

.o-stripeCheckoutForm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 15px;

  &__content {
    margin: auto;
    background: #fff;
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    &--card {
      width: 100%;
      padding: 18.5px 14px;
      border-radius: 4px;
      border: 1px solid #ccc;
      iframe {
        width: 100%;
      }
    }
  }

  &__offer {
    width: 100%;

    &--placeholder {
      max-width: 600px;
      width: 100%;
      height: 400px;
      background: #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    header {
      background-color: #e6e6e6;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      b {
        font-size: 22px;
      }

      span {
        font-size: 18px;
      }
    }

    main {
      padding: 15px;

      p {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
      }

      ul {
        margin-top: 15px;

        li {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 10px;

          &:nth-of-type(odd) {
            background-color: #fafafa;
          }

          span {
            &:last-child {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  &__form {
    position: relative;
    &Loader {
      z-index: 999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
    }

    &Error {
      width: calc(100% - 40px);
      background: rgba(255, 0, 0, 0.2);
      padding: 10px 20px;
      border-radius: 6px;
      font-size: 14px;
      margin: 0 20px 20px;
    }

    &Group {
      padding: 20px;
    }

    header {
      background-color: #e6e6e6;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      b {
        font-size: 22px;
      }

      span {
        font-size: 18px;
      }
    }
  }

  &__fields {
    display: flex;
    justify-content: space-between;

    &--exp,
    &--cvc {
      margin-left: 10px;
    }

    &--email,
    &--phone {
      width: 100%;
      max-width: calc(50% - 5px);
    }

    &--number {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
  }
}
