@import 'styles/_variables';
@import 'styles/_mixins';

.m-chatMessage {
  max-width: 55%;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-start;
  display: flex;

  &__message {
    position: relative;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0 4px 10px rgba(24, 161, 219, 0.25);
    border-radius: 0 10px 10px;
    background-color: $colorPrimary;
    padding: 15px 15px 20px;
    width: auto;
    min-width: 100px;
    display: inline-flex;

    &--document,
    &--video {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        width: 20px;
        height: auto;

        rect,
        path {
          fill: $colorPrimary;
        }
      }
    }

    &--loading,
    &--image {
      border-radius: 8px;
      overflow: hidden;

      &.-placeholder {
        width: 150px;
        background: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 150px;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &.-loading,
    &.-image {
      width: 150px;
      height: 100px;
      padding: 5px;

      .m-chatMessage__sentAt {
        background: rgba(255, 255, 255, 0.8);
        text-align: right;
        padding: 2px 7px;
        border-radius: 5px;
        color: #333;
        opacity: 1 !important;
        bottom: 10px;
        right: 10px;
      }
    }
  }

  &__sentAt {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  &.-system {
    justify-content: center !important;
    margin: 0 auto 40px;
    max-width: 100% !important;
    background: transparent !important;
    box-shadow: unset !important;
    overflow: visible !important;

    .m-chatMessage {
      &__button {
        padding: 5px 20px;
        border-radius: 5px;
        margin-top: 10px;
        background: $colorPrimary;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
      }

      &__message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column !important;
        border-radius: 6px;
        background: #dedede;
        box-shadow: 0 4px 10px rgba(211, 211, 211, 0.25);
        color: #282828;
        text-align: center;
        font-size: 12px;
        padding: 15px 30px;

        &.-linkMessage {
          background: #a8e9ff;
          transition: all 0.2s linear;

          &:hover {
            background: #77ddff;
          }
        }

        &.-videoRoom {
          cursor: default;
          background: #fff;
          border: 2px solid $colorPrimary;

          button {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            svg {
              margin-right: 10px;
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      &__sentAt {
        opacity: 0.7;
        color: #282828;
        top: 100%;
        right: 0;
        bottom: auto;
        z-index: 100;
        margin-top: 5px;
        width: 100%;
      }
    }
  }

  &.-sender {
    justify-content: flex-end;
    margin-left: auto;

    .m-chatMessage {
      &__message {
        box-shadow: 0 4px 10px rgba(211, 211, 211, 0.25);
        border-radius: 10px 0 10px 10px;
        background-color: #fff;
        color: rgba(40, 40, 40, 0.7);
        font-size: 16px;
        font-weight: 400;
      }

      &__sentAt {
        opacity: 0.7;
        color: #282828;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }

  &.-receiver {
    align-items: center;

    &:hover {
      .report {
        display: block;
      }
    }
    .report {
      display: none;
      margin-left: 15px;
      fill: #bbb;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        fill: #dc3545;
        transition: all 0.2s linear;
      }
    }
  }

  &.-offer {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(211, 211, 211, 0.25);
    max-width: 600px;
  }

  &__offer {
    width: 100%;

    &--placeholder {
      max-width: 600px;
      width: 100%;
      height: 400px;
      background: #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    header {
      background-color: #e6e6e6;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      b {
        font-size: 22px;
      }

      span {
        font-size: 18px;
      }
    }

    main {
      padding: 15px;

      p {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
      }

      ul {
        margin-top: 15px;

        li {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 10px;

          &:nth-of-type(odd) {
            background-color: #fafafa;
          }

          span {
            &:last-child {
              font-size: 18px;
            }
          }
        }
      }

      .m-chatMessage__offer--paymentDetails {
        margin-top: 15px;

        &-item {
          &.-withBorder,
          &:first-child {
            border-bottom: 1px solid #e6e6e6;
            padding-bottom: 10px;
          }

          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          color: #777;
        }

        p {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          color: #777;
          font-size: 14px;
        }
      }
    }

    footer {
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &--offerAccepted {
      width: auto;
      height: 42px;
      background-color: #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      margin-left: 15px;
      border-radius: 6px;
      color: #999;
      text-transform: uppercase;
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 1;
      cursor: default;
    }
  }
}
