@import 'styles/_variables';
@import 'styles/_mixins';

.m-reviews {
  max-width: 1170px;
  margin: auto;
  padding-bottom: 30px;

  &__item {
    max-width: 360px;
    position: relative;


    &--content {
      border: 1px solid #cdd2d4;
      background-color: #fff;
      display: flex;
      align-items: flex-start;
      position: relative;
      z-index: 3;
      padding: 15px;
      @media(max-width: 340px){
        flex-direction: column;
      }
      &-header {
        display: flex;
        width: 100%;
      }
    }

    &--rating {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media(max-width: 340px){
        flex-direction: column;
      }
      &-stars {
        display: flex;
        flex-direction: column;
        @media(max-width: 340px){
          align-items: center;
        }
      }
      small {
        color: #3b3b3b;
        padding-top: 5px;
        white-space: nowrap;
        @media(max-width: 340px){
          text-align: center;
        }
      }
    }

    &--image {
      position: relative;
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eee;
      margin-right: 15px;

      @media(max-width: 340px){
        margin: 0 auto 15px;
      }
      span {
        font-weight: 500;
        color: $colorPrimary;
        font-size: 24px;
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        position: relative;
        z-index: 2;
      }
    }

    &--info {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      width: 100%;
      @media(max-width: 340px){
        align-items: center;
      }
      strong {
        font-size: 18px;
        color: #0f0e20;
        font-weight: 700;
        padding-top: 15px;
        @media(max-width: 340px){
          text-align: center;
        }
      }
      p {
        color: #a2a2a2 !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        width: 100%;
        display: inline-block;
        margin: 15px 0 0;
        @media(max-width: 340px){
          text-align: center;
        }
      }
    }
  }

  &__chevron {
    &--left,
    &--right {
      width: 65px;
      height: 65px;
      background-color: #e2f6ff;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: -80px;
      margin-top: -33px;
      transition: all 0.2s linear;
      @media (max-width: 1365px) {
        left: -30px;
      }

      @media (max-width: 859px) {
        left: 60px;
      }

      @include media-breakpoint-down(sm) {
        left: 30px;
      }

      @include media-breakpoint-down(xs) {
        top: 100%;
        left: 50%;
        margin: 0 0 0 -75px;
      }

      &:after,
      &:before {
        content: '';
        width: 15px;
        height: 15px;
        display: block;
        border-left: 4px solid $colorPrimary;
        border-top: 4px solid $colorPrimary;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -7.5px 0 0 -10px;
        transform: rotate(-45deg);
        transition: all 0.2s linear;
      }

      &:after {
        margin-left: 0;
      }

      &:hover {
        background: $colorPrimary;

        &:before,
        &:after {
          border-color: #fff;
        }
      }
    }

    &--right {
      left: auto;
      right: -80px;

      @media (max-width: 1365px) {
        right: -30px;
      }
      @media (max-width: 859px) {
        right: 60px;
      }
      @include media-breakpoint-down(sm) {
        right: 30px;
      }

      @include media-breakpoint-down(xs) {
        right: 100px;
      }

      &:after,
      &:before {
        margin: -7.5px 0 0 -13px;
        transform: rotate(135deg);
      }

      &:after {
        margin-left: -3px;
      }
    }
  }
}
