@import "styles/_variables";
@import "styles/_mixins";

.m-chatActions {
  position: relative;

  &__dropdown {
    position: absolute;
    box-shadow: 0 6px 7px #ececec;
    background-color: #fff;
    width: 230px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    opacity: 0;
    transition: all .2s linear;
    pointer-events: none;
    bottom: 120px;

    &.-show {
      opacity: 1;
      pointer-events: initial;
      bottom: 65px;
    }

    &:after {
      content: '';
      border-top: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      width: 0;
      height: 0;
      top: 100%;
      left: 20px;
    }

    button {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding: 0;
      outline: none;

      svg {
        margin: auto;
      }

      i {
        margin-right: 10px;
        width: 20px;
        text-align: center;
      }

      span {
        color: #282828;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &__trigger{
    transition: all .2s linear;
    outline: none !important;
    &.-open{
      transform: rotate(45deg);
    }
  }
}
