@import 'styles/_variables';
@import 'styles/_mixins';

.m-notificationItem {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 20px;
  outline: none !important;
  transition: all 0.2s linear;

  &:hover {
    background: rgba(250, 250, 250, 0.7);
  }

  &.-unread {
    background: rgba(24, 161, 219, 0.03);
  }

  &__icon {
    width: 40px;
    flex-shrink: 0;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 100%;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;

    @include media-breakpoint-down(xs) {
      margin-right: 15px;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #333 !important;
      }
    }
  }

  &__content {
    color: #a2a2a2;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: flex-start;
    }

    &--text {
      text-align: left;
      margin-right: 30px;

      @include media-breakpoint-down(xs) {
        margin: 0;
        order: 2;
      }
    }

    a {
      color: #424143;
      transition: all 0.2s linear;

      &:hover {
        color: $colorPrimary;
      }
    }

    &--date {
      flex-shrink: 0;
      margin-left: auto;
      color: #a2a2a2;
      font-size: 12px;
      font-weight: 400;

      @include media-breakpoint-down(xs) {
        margin: 0 0 10px;
        order: 1;
      }

      small {
        margin-right: 15px;
        @include media-breakpoint-down(xs) {
          color: #a2a2a2 !important;
          font-size: 12px !important;
          margin-right: 5px;
          &:after {
            content: ',';
          }
        }
      }

      span {
        color: #fff;
        background: #333;
        padding: 5px 10px;
        border-radius: 25px;

        @include media-breakpoint-down(xs) {
          color: #a2a2a2 !important;
          font-size: 12px !important;
          padding: 0;
          background: transparent !important;
        }
      }
    }
  }

  &.-review {
    .m-notificationItem__icon {
      border: 1px solid rgba(255, 193, 7, 0.2);
      background-color: rgba(255, 193, 7, 0.2);

      svg {
        path {
          fill: #ffc107 !important;
        }
      }
    }

    .m-notificationItem__content--date {
      span {
        background: #ffc107;
      }
    }
  }

  &.-payment,
  &.-completed,
  &.-extend-time,
  &.-extend-time-accepted,
  &.-offer-accepted,
  &.-payment-made {
    .m-notificationItem__icon {
      background-color: rgba(26, 219, 116, 0.2);
      border: 1px solid rgba(26, 219, 116, 0.2);

      svg {
        path {
          fill: #1adb74 !important;
        }
      }
    }

    .m-notificationItem__content--date {
      span {
        background: #1adb74;
      }
    }
  }

  &.-completed-accepted,
  &.-case-started,
  &.-offer-sent,
  &.-upload-file {
    .m-notificationItem__icon {
      background-color: rgba(24, 161, 219, 0.2);
      border: 1px solid rgba(24, 161, 219, 0.2);

      svg {
        path {
          fill: #18a1db !important;
        }
      }
    }

    .m-notificationItem__content--date {
      span {
        background: #18a1db;
      }
    }
  }
}
