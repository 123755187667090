@import 'styles/_variables';
@import 'styles/_mixins';

.m-hourlyRates {
  header {
    border-bottom: 1px solid rgba(219, 24, 24, 0.2);
    background-color: rgba(219, 24, 24, 0.2);

    span {
      color: #db1818;
      font-size: 24px;
      font-weight: 400;
    }

    svg {
      path {
        fill: #db1818 !important;
      }
    }
  }
}