@import 'styles/_variables';
@import 'styles/_mixins';

.m-reviewItem{
  border-bottom: 1px solid #f3f3f3;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &__user{
    display: flex;
    align-items: flex-start;
    &--review{
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      p{
        padding-top: 5px;
        color: #050e30;
        font-size: 14px;
        font-weight: 400;
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }

      button{
        width: auto !important;
        background: transparent !important;
        padding: 0 !important;
        font-size: 12px !important;
        outline: none;
        &:hover{
          color: #999 !important;
        }
      }
    }
  }
  &__date{
    color: #53586e;
    font-size: 12px;
    font-weight: 400;
  }
}