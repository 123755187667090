@import 'styles/_variables';
@import 'styles/_mixins';

.m-account {
  header {
    border-bottom: 1px solid rgba(225, 177, 33, 0.2);
    background-color: rgba(225, 177, 33, 0.2);

    span {
      color: #e1b121;
      font-size: 24px;
      font-weight: 700;
    }
  }

  main {
    padding: 20px;
  }
  &__item{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    strong{
      color: #050e30;
      font-weight: 600;
      margin-bottom: 5px;
    }
    span{
      color: #53586e;
      font-size: 14px;
    }
    small{
      color: #9da0ad;
      font-size: 12px;
    }
    a{
      color: $colorPrimary;
      font-size: 14px;
      text-decoration: underline;
    }
  }
}