@import 'styles/_variables';
@import 'styles/_mixins';

.t-mainHeader {
  position: absolute;
  z-index: 3;
  width: 100%;
  padding: 50px 30px 0;

  &.-login {
    display: none;
  }

  &__logo {
    max-width: 160px;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
    &.-login {
      display: block;
    }
  }

  &__toggleNav {
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
      background: transparent;
      width: 22px;
      height: 22px;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      z-index: 999;
      outline: none !important;
      span {
        position: relative;
        width: 22px;
        height: 4px;
        background: $colorPrimary;
        border-radius: 1px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        &:before,
        &:after {
          content: '';
          display: inline-block;

          width: 22px;
          height: 4px;
          background: $colorPrimary;
          border-radius: 1px;
          position: absolute;
          top: -8px;
          left: 0;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        &:after {
          top: 8px;
        }
      }
      &.-close {
        background: transparent;

        span {
          background: transparent;

          &:before {
            transform: rotate(45deg) translate(5px, 6px);
          }

          &:after {
            transform: rotate(-45deg) translate(5px, -6px);
          }
        }
      }
    }
  }

  &__nav {
    @include media-breakpoint-down(sm) {
      display: none;

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.8);
      z-index: 998;
      align-items: center;
      justify-content: center;
      &.-show {
        display: flex;
      }
    }

    a {
      cursor: pointer;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
      margin-left: 60px;
      transition: all 0.2s linear;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
        margin-left: 40px;
      }
      @include media-breakpoint-down(md) {
        margin-left: 25px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 1;
        margin-bottom: 30px;
        position: relative;
      }

      &:hover {
        color: #18a1db;
      }

      &.-join {
        color: $colorPrimary;
        background: transparent;
        border: 2px solid $colorPrimary;
        padding: 15px 50px;
        font-weight: 500;
        font-size: 18px;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          padding: 15px 40px;
        }
        @include media-breakpoint-down(md) {
          padding: 12px 20px;
        }

        &:hover {
          background: $colorPrimary;
          color: #fff;
        }
      }
    }
  }

  &__dropdown {
    position: relative;

    &--trigger {
      cursor: pointer;
      border: 2px solid #12b87f;
      border-radius: 100%;
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #12b87f;
      }
    }

    &--box {
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      min-width: 220px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      align-items: center;
      position: relative;
      z-index: 2;
      overflow: hidden;
    }

    &--header {
      background: #fff;
      padding: 30px 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        margin-top: 10px;
        font-weight: bold;
        color: #333;
        width: 100%;
        text-align: center;
      }

      a {
        color: #666;

        small {
          margin: 0 5px;
          font-size: 12px;
        }

        svg {
          width: 15px;
        }
      }
    }

    &--avatar {
      border-radius: 100%;

      span {
        font-weight: normal;
        color: #fff;
      }
    }

    &--content {
      position: absolute;
      top: 100%;
      right: -4px;
      margin-top: 20px;
      display: none;

      &.-open {
        display: block;
      }

      &:before,
      &:after {
        content: '';
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: 100%;
        right: 15px;
        z-index: 3;
      }

      &:after {
        border-bottom-color: #ccc;
        margin-bottom: 2px;
        z-index: 1;
        filter: blur(2px);
      }

      button {
        background: $colorPrimary;
        color: #fff;
        padding: 15px;
        width: 100%;

        span {
          margin-right: 10px;
        }
      }
    }
  }

  &.-relative {
    position: relative;
    box-shadow: 0 8px 23px rgba(185, 185, 185, 0.19);
    background-color: #fff;
    margin-bottom: 30px;
    padding: 30px;
    z-index: 99;

    .t-mainHeader__nav {
      a {
        color: #bebebe;

        &.-current,
        &:hover {
          color: $colorPrimary;
        }

        &.-join {
          border: 1px solid $colorPrimary;
          color: $colorPrimary;
          @include media-breakpoint-down(sm) {
            color: #fff;
            background: $colorPrimary;
            font-size: 18px;
          }

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  &.-panel {
    position: relative;
    border-bottom: 1px solid #f4f4f4;
    padding: 0 30px;
    font-family: $defaultPanelFontFamily;

    @include media-breakpoint-up(md) {
      .t-mainHeader__nav {
        margin-left: auto;
        margin-right: 30px;
        height: 100%;

        &--link {
          cursor: pointer;
          color: #2d2d2d;
          font-size: 17px;
          font-weight: 400;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          height: 100%;
          padding: 30px 0;
          line-height: 1;
          display: inline-flex;
          text-transform: none;

          &:hover,
          &.-current {
            color: $colorPrimary;
            font-size: 17px;
            font-weight: 400;
            border-bottom-color: $colorPrimary;
          }

          &-notification {
            width: 18px;
            height: 18px;
            background-color: red;
            border-radius: 100%;
            margin: -10px 0 0 -3px;
            font-size: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 15px 30px;
      margin-bottom: 0;

      .t-mainHeader__dropdown--trigger {
        margin-right: 20px;
        margin-left: auto;
      }
    }
  }

  .-show {
    @include media-breakpoint-down(sm) {
      .t-mainHeader__nav--link-notification {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        margin: -5px 0 0;
        position: absolute;
        background-color: red;
        font-size: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        top: -7px;
        right: -15px;
      }
    }
  }
}

.t-teamInvites {
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;

  &__item {
    background: $colorPrimary;
    padding: 10px 30px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    a {
      text-decoration: underline;

      &:hover {
        color: #024663;
      }
    }

    &--actions {
      border-left: 1px solid #057db1;
      padding-left: 30px;
      margin-left: 30px;
    }

    button {
      width: auto;
      color: #fff;
      border-radius: 30px;
      padding: 5px 20px;
      background: #057db1;
      white-space: nowrap;
      font-size: 14px;

      &.-decline {
        margin-right: 15px;
      }

      &:hover {
        background: #045f87;
      }
    }
  }
}

.t-accountActivation {
  width: 100%;
  height: 100%;
  background: $colorSecondary;
  padding: 45px 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    max-width: 1366px;
    width: 100%;
    position: relative;
    background: rgba(255,255,255,.3);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #333;
    padding: 30px;
    text-align: left;
    border-radius: 5px;
    header {
      margin-bottom: 30px;
      width: 100%;
      h4 {
        width: 100%;
        font-size: 24px;
        color: $colorPrimary;
      }
      b{
        color: #fff;
        text-align: center;
        font-size: 18px;
        width: 100%;
        display: block;
        background: $colorPrimary;
        padding: 15px;
        border-radius: 5px;
      }
    }
    footer {
      border-top: 1px solid rgba(255,255,255,.2);
      margin-top: 15px;
      width: 100%;
      text-align: center;
      small{
        max-width: 960px;
        margin:15px auto 0;
      }
    }

    small {
      display: block;
      font-size: 12px;
      margin-top: 15px;
      color: #ccc;
    }
    ul {
      width: 100%;
      li {
        display: flex;
        align-items: center;
        line-height: 1;
        color: #fff;
        width: 100%;
        @media(max-width: 480px){
          flex-wrap: wrap;
        }
        &.-disabled {
          p, span,
          svg, button {
            opacity: 0.3;
          }
          button{
            background: $colorPrimary !important;
            cursor: default;
            outline: none;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(255,255,255,.2);
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
        svg {
          width: 22px;
          height: 22px;
          margin-right: 10px;
          padding-bottom: 2px;
          path {
            fill: #ccc;
          }
        }
        a,
        button {
          color: #fff;
          background: $colorPrimary;
          font-size: 14px;
          margin-left: auto;
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;
          @media(max-width: 480px){
            width: 100%;
            margin-left: 0;
            margin-top: 15px;
            text-align: center;
          }
          &:hover {
            background: $colorPrimaryHover;
          }
        }
        span{
          margin-left: auto;
          font-size: 14px;
          padding: 5px 10px;
          border-radius: 5px;
          background: #12b87f;
          color: #fff;
          line-height: 1;

          &.-verifying{
            margin-left: 15px;
            background: transparent;
            color: #ccc;
            border: 1px solid #ccc;
            font-size: 12px;
          }

          @media(max-width: 480px){
            width: 100%;
            margin-left: 0;
            margin-top: 15px;
            text-align: center;
          }
        }
        &.-complete{
          p{
            color: #12b87f;
          }
          svg{
            path{
              fill: #12b87f;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
    //padding: 30px;
    //p{
    //  margin-bottom: 15px;
    //}
    //a{
    //  margin: 30px 0 0;
    //}
  }
}
